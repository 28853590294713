<template>
  <loading v-if="is_loading" />
  <div class="show-explore py-2" v-else-if="course_data">
    <overlay-component :isLoading="overlay_loading" />
    <explore-overview-component :data="course_data" />
    <explore-lessons-component
      v-if="course_data.lessons.length"
      :data="course_data"
      :isPackage="false"
    />
  </div>
</template>

<script>
import { BCol, BButton } from "bootstrap-vue";
import loading from "@/components/loading/loading.vue";
import OverlayComponent from "@/components/shared/OverlayComponent";
import ExploreLessonsComponent from "@/components/Explore/ShowCourse/ExploreLessonsComponent/index.vue";
import ExploreOverviewComponent from "@/components/Explore/ShowCourse/ExploreOverviewComponent/index.vue";

export default {
  components: {
    BCol,
    BButton,
    loading,
    OverlayComponent,
    ExploreLessonsComponent,
    ExploreOverviewComponent,
  },
  data() {
    return {
      course_id: null,
      is_loading: true,
      overlay_loading: false,
      course_data: null,
    };
  },
  beforeMount() {
    if (this.$route.params) {
      this.course_id = this.$route.params.id;
      this.getCourseData(this.$route.params.id);
    } else {
      this.$router.push("/");
    }
  },
  methods: {
    makeToast(variant = null, body, title) {
      this.$bvToast.toast(this.$t(body), {
        title: this.$t(title),
        variant,
        solid: true,
      });
    },
    async getCourseData(id) {
      try {
        this.is_loading = true;
        const res = await this.$http.get(`/guardian/courses/${id}`);
        this.course_data = res.data.data;
      } catch (error) {
        for (const key in error.response?.data?.errors) {
          this.makeToast(
            "danger",
            error.response.data.errors[key][0],
            this.$t("g.send.errorTitle")
          );
        }
      } finally {
        this.is_loading = false;
      }
    },
  },
};
</script>

<style lang="scss">
@import "./_index.scss";
</style>
